import PropTypes from 'prop-types';
import React from 'react';
import slugify from 'slugify';
import css from 'styled-jsx/css';

import Link from '../Link';
import LinkButton from '../LinkButton';

import { veryLightPink } from '../../css-utilities';

const linkStyle = css.resolve`
  text-decoration: none;
`;

const CourseCardNoOccasions = ({ name }) => (
  <div className="no-occasions">
    <Link
      className={linkStyle.className}
      to={`/utbildningar/${slugify(name, {
        lower: true,
      })}/intresserad`}
    >
      <div className="content">
        <p className="text">Inga kurstillfällen just nu...</p>
        <LinkButton
          arrowDirection="right"
          label="Anmäl intresse"
          to={`/utbildningar/${slugify(name, {
            lower: true,
          })}/intresserad`}
        />
      </div>
    </Link>

    {linkStyle.styles}

    <style jsx>
      {`
        .no-occasions {
          z-index: 2;
          width: 100%;
          text-align: center;
          border-top: 1px solid ${veryLightPink};
          border-bottom: 1px solid ${veryLightPink};
        }
        .content {
          padding: 40px 0;
        }
        .text {
          padding: 0 0 15px 0;
          margin: 0;
          text-decoration: none;
        }
      `}
    </style>
  </div>
);

CourseCardNoOccasions.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CourseCardNoOccasions;
