import React from 'react';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import Navigation from '../Navigation';
import Footer from '../Footer';
import CookieBanner from '../CookieBanner';

import {
  greyishBrown,
  bluishPurple,
  greenishcyan,
  breakpoints,
} from '../../css-utilities';

const Document = ({ children, seo, favicon }) => (
  <>
    <HelmetDatoCms favicon={favicon} seo={seo} />

    <div className="wrapper">
      <Navigation />

      <main className="main">
        <CookieBanner />
        {children}
      </main>

      <Footer />
    </div>

    <style jsx>
      {`
        .wrapper {
          display: grid;
          grid-template-rows: auto 1fr auto;
          min-height: 100%;
          min-height: 100vh;
        }
        .main {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: 20px 1fr 1fr 20px;
        }

        @media (min-width: ${breakpoints.tablet}) {
          .main {
            grid-template-columns: 13% 1fr 1fr 13%;
          }
        }

        @media (min-width: ${breakpoints.desktopMD}) {
          .main {
            grid-template-columns: 1fr repeat(2, minmax(350px, 550px)) 1fr;
          }
        }
      `}
    </style>

    <style global jsx>
      {`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Poppins';
          line-height: normal;
          font-weight: bold;
          line-height: 1.4;
        }

        h1 {
          font-size: 40px;
        }

        h2 {
          font-size: 32px;
        }

        h3 {
          font-size: 28px;
        }

        h4,
        h5,
        h6 {
          font-size: 20px;
        }

        p {
          font-size: 18px;
          color: ${greyishBrown};
          line-height: 1.56;
          font-weight: 300;
        }

        li,
        p,
        span {
          font-family: 'Lato';
        }

        .header--underscore {
          position: relative;
          display: inline-block;
        }

        .header--underscore::before {
          content: '';
          display: block;
          position: absolute;
          width: 50%;
          height: 4px;
          background: ${greenishcyan};
          border-radius: 2px;
          left: 50%;
          transform: translateX(-50%);
          bottom: -15px;
        }

        .section-container {
          position: relative;
          max-width: calc(100vw - 10vw);
          margin: 0 auto;
        }

        .btn {
          display: inline-flex;
          align-items: center;
          padding: 13px 35px;
          font-family: 'Lato';
          font-size: 14px;
          color: ${bluishPurple};
          background: #fff;
          text-transform: uppercase;
          transition: 0.3s all ease-in-out;
          border-radius: 20px;
          border: none;
          box-shadow: 0 3px 8px #cad5e0;
          cursor: pointer;
          text-decoration: none;
        }

        .btn.dark {
          color: #fff;
          background: ${bluishPurple};
        }

        .btn .arrow {
          margin-left: 10px;
          font-size: 10px;
        }

        .btn:hover,
        .btn:focus {
          transform: scale(0.97);
          box-shadow: 0 3px 10px #cad5e0;
          transition: 0.3s all ease-in-out;
        }

        .btn:focus {
          box-shadow: 0 3px 10px #9ca7b2;
        }

        .flex {
          display: flex;
        }

        .flex-d-col {
          flex-direction: column;
        }

        .space-between {
          justify-content: space-between;
        }

        .wrap {
          flex-wrap: wrap;
        }

        @media (max-width: ${breakpoints.tablet}) {
          .flex.wrap.tablet {
            width: 100%;
          }
        }

        img {
          max-width: 100%;
        }

        [data-whatintent='mouse'] *:focus {
          outline: none;
        }

        .g-recaptcha {
          display: none;
        }
      `}
    </style>

    {/* normalize / reset  */}
    <style global jsx>
      {`
        body {
          overflow-x: hidden;
          margin: 0;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
        }

        a:visited,
        a:active {
          text-decoration: none;
        }

        /* https://necolas.github.io/normalize.css/ */

        html {
          line-height: 1.15; /* 1 */
          -webkit-text-size-adjust: 100%; /* 2 */
          overflow-x: hidden;
        }

        body {
          margin: 0;
        }

        a {
          background-color: transparent;
        }

        b,
        strong {
          font-weight: bolder;
        }

        code {
          font-family: monospace, monospace; /* 1 */
          font-size: 1em; /* 2 */
        }

        small {
          font-size: 80%;
        }

        img {
          border-style: none;
        }

        button,
        input,
        optgroup,
        select,
        textarea {
          font-family: 'Lato'; /* 1 */
          font-size: 100%; /* 1 */
          line-height: 1.15; /* 1 */
          margin: 0; /* 2 */
        }

        button,
        input {
          /* 1 */
          overflow: visible;
        }

        button,
        select {
          /* 1 */
          text-transform: none;
        }

        button,
        [type='button'],
        [type='reset'],
        [type='submit'] {
          -webkit-appearance: button;
        }

        progress {
          vertical-align: baseline;
        }

        [type='checkbox'],
        [type='radio'] {
          box-sizing: border-box; /* 1 */
          padding: 0; /* 2 */
        }

        [type='number']::-webkit-inner-spin-button,
        [type='number']::-webkit-outer-spin-button {
          height: auto;
        }

        [type='search'] {
          -webkit-appearance: textfield; /* 1 */
          outline-offset: -2px; /* 2 */
        }

        [type='search']::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        ::-webkit-file-upload-button {
          -webkit-appearance: button; /* 1 */
          font: inherit; /* 2 */
        }

        [hidden] {
          display: none;
        }

        h1 + p,
        h2 + p,
        h3 + p,
        h4 + p {
          margin-top: 0;
        }
      `}
    </style>
  </>
);

Document.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  favicon: PropTypes.object.isRequired,
  seo: PropTypes.object.isRequired,
};

export default Document;
