import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';

import Button from '../Button';
import {
  veryLightBluishGray,
  darkPurple,
  breakpoints,
} from '../../css-utilities';
import { boxShadow } from '../../styles';

const cookieButton = css.resolve`
  margin: 10px 0 0;
  display: inline-block;
  font-weight: bold;

  @media (min-width: ${breakpoints.desktopSM}) {
    margin: 0 20px;
  }
`;

const CookieBanner = ({ acceptCookies, onClose }) => {
  return (
    <div className="cookie-notice flex">
      <button className="btn--close" onClick={onClose}>
        <i className="fas fa-times" />
      </button>
      <p>
        På denna hemsida använder vi cookies för att förbättra din upplevelse.
        Genom att använda denna webbplats godkänner du detta.
      </p>
      <Button
        className={cookieButton.className}
        dark
        label="OK"
        onClick={acceptCookies}
      />

      {cookieButton.styles}

      <style jsx>
        {`
          .cookie-notice {
            width: 90%;
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            padding: 10px 20px;
            background-color: ${veryLightBluishGray};
            box-shadow: ${boxShadow};
            align-items: center;
            flex-direction: column;
          }

          p {
            margin: 0 20px 0 0;
            font-size: 16px;
            width: auto;
          }

          i {
            color: ${darkPurple};
            font-size: 20px;
          }

          .btn--close {
            padding: 8px;
            background: none;
            border: none;
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
            z-index: 1;
          }
          @media (min-width: ${breakpoints.tablet}) {
            .cookie-notice {
              width: auto;
              max-width: 800px;
            }
            p {
              margin: 0 20px 0 0;
            }
          }
          @media (min-width: ${breakpoints.desktopSM}) {
            .cookie-notice {
              padding: 10px 40px;
              flex-direction: row;
            }
            p {
              margin: 0;
            }
            .btn--close {
              top: 5px;
              right: 10px;
            }
          }
        `}
      </style>
    </div>
  );
};

CookieBanner.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CookieBanner;
