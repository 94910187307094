import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import css from 'styled-jsx/css';

import Link from '../Link';

import {
  darkPurple,
  veryLightpinkLighter,
  breakpoints,
} from '../../css-utilities';

const linkStyles = css.resolve`
  font-family: Poppins;
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 16px;
  outline: none;

  .active,
  :focus,
  :hover {
    color: ${veryLightpinkLighter};
  }
`;

const smallLink = css.resolve`
  color: white;
  text-decoration: none;
`;

const Footer = ({ links, adress, phone, urlName, urlPath }) => {
  const Links = links.map(link => (
    <Link
      activeClassName={'active'}
      className={linkStyles.className}
      key={link.path}
      to={`/${slugify(link.path, { lower: true })}`}
    >
      {link.title}
    </Link>
  ));

  return (
    <footer>
      <div className="links">{Links}</div>

      <div className="info">
        <span>{adress}</span>
        <span>{phone}</span>
        <span>
          <Link className={smallLink.className} to={urlPath}>
            {urlName}
          </Link>
        </span>
      </div>

      {smallLink.styles}
      {linkStyles.styles}

      <style jsx>
        {`
          footer {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 25px 10px 30px 10px;
            background: ${darkPurple};
          }

          .links,
          .info {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
          }

          .links {
            margin-bottom: 20px;
          }

          .info {
            color: white;
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .links,
            .info {
              flex-direction: row;
              justify-content: center;
            }
            .info span {
              position: relative;
              padding: 0 15px;
            }
            .info span:after {
              position: absolute;
              top: 0;
              right: 0;
              content: '';
              display: block;
              width: 2px;
              height: 100%;
              background: #fff;
            }
            .info span:last-child:after {
              display: none;
            }
          }
        `}
      </style>
    </footer>
  );
};

Footer.propTypes = {
  adress: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
  urlName: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
};

export default Footer;
