import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import NavbarItemsPresentational from './NavbarItems';

const NavbarItems = props => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      datoCmsNavigation {
        links {
          title
          path
        }
      }
    }
  `);

  let { links } = data.datoCmsNavigation;

  return <NavbarItemsPresentational {...props} links={links} />;
};

export default NavbarItems;
