import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children, noPadding, className, background, noMargin }) => (
  <section className={`section ${className ? className : ''}`}>
    {children}

    <style jsx>
      {`
        .section {
          grid-column: 2 / 4;
          background: ${background ? background : 'none'};
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          padding: ${noPadding ? 0 : '50px 0'};
          margin: ${noMargin ? 0 : '50px 0'};
        }
      `}
    </style>
  </section>
);

Section.defaultProps = {
  background: null,
  className: null,
  noMargin: false,
  noPadding: false,
};

Section.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export default Section;
