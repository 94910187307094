import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';
import css from 'styled-jsx/css';

import NavbarItems from './NavbarItems';
import MenuButton from './MenuButton';

import { darkPurple, breakpoints } from '../../css-utilities';
import logo from '../../images/wa-logo-2018-cirkel-symbol.png';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggleNavBar = useCallback(() => {
    setIsNavOpen(!isNavOpen);
  }, [isNavOpen]);

  return (
    <header className={`navigation ${isNavOpen ? 'open' : ''}`} id="navigation">
      <div className="brand-container">
        <Link className={brandContainerLink.className} to="/">
          <img alt="logo" src={logo} />
          <span>Wa Education</span>
        </Link>
      </div>

      <NavbarItems isOpen={isNavOpen} />
      <MenuButton isNavOpen={isNavOpen} toggleNav={handleToggleNavBar} />

      {brandContainerLink.styles}

      <style jsx>
        {`
          .navigation {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 25px;
            font-family: 'Poppins';
            background-color: #f6f9fc;
            color: ${darkPurple};
          }

          .brand-container {
            display: flex;
            align-items: center;
            z-index: ${isNavOpen ? 15 : 0};
            padding: 30px 0;
          }

          .brand-container span {
            display: none;
            font-size: 23px;
            text-transform: uppercase;
            margin-left: 16px;
          }

          .navigation.open .brand-container {
            position: fixed;
            top: 0;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .navigation.open .brand-container {
              position: static;
              top: auto;
            }

            .brand-container {
              padding: 30px 0;
            }
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .brand-container span {
              display: inline;
            }
          }

          .brand-container img {
            width: 40px;
          }
        `}
      </style>
    </header>
  );
};

const brandContainerLink = css.resolve`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${darkPurple};
`;

export default Header;
