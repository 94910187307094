export const veryLightPink = '#cbcbcb';
export const bluishPurple = '#6449da';
export const darkishPink = '#f23288';
export const veryLightpinkLighter = '#e2e2e2';
export const sunFlowerYellow = '#ffdd00';
export const greenishcyan = '#50e3c2';
export const greyishBrown = '#4a4a4a';
export const brownGrey = '#9b9b9b';
export const darkPurple = '#2f2e73';
export const veryLightBluishGray = '#f6f9fc';
export const white = '#fff';
export const black = '#000';

export const purpleLinearGradient = `linear-gradient(
    101deg,
    #8432de,
    #6746da 63%,
    ${bluishPurple}
  )`;

export const breakpoints = {
  tablet: '768px',
  desktopSM: '1024px',
  desktopMD: '1440px',
  desktopLG: '2560px',
};
