import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import css from 'styled-jsx/css';

import Ribbon from './Ribbon';
import CourseOccasions from '../CourseOccasions';
import CourseCardNoOccasions from '../CourseCardNoOccasions';

import {
  veryLightBluishGray,
  white,
  bluishPurple,
  breakpoints,
} from '../../css-utilities';

import {
  formatCurrencySEK,
  dateIsTodayOrInTheFuture,
  slugLowerCase,
  firstDay,
} from '../../lib/utils';

const linkStyle = css.resolve`
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const CourseCard = ({
  course: {
    node: { name, description, length, price, occasions },
  },
  theme = 'dark',
  showMaximum = occasions.length,
}) => {
  const occasionsInTheFuture = occasions.filter(({ allDates }) => {
    let firstDayDate = firstDay(allDates);
    return dateIsTodayOrInTheFuture(firstDayDate);
  });

  const filteredOccasions = occasionsInTheFuture.filter(
    (_, i) => i + 1 <= showMaximum
  );

  const courseHasItemWithOffer = occasions.some(({ discount }) => discount);

  return (
    <div className="course-card">
      <Link
        className={linkStyle.className}
        to={`/utbildningar/${slugLowerCase(name)}`}
      />

      {courseHasItemWithOffer && <Ribbon text="Passa på!" />}

      <h3 className="name">{name}</h3>

      <p className="description">{description}</p>

      <div className="details">
        <p className="details-price">{`Pris: ${formatCurrencySEK(price)}`}</p>
        <p className="details-length">
          {`Längd: ${length} ${length > 1 ? 'timmar' : 'timme'}`}
        </p>
      </div>

      <div className="occasions">
        {filteredOccasions.length ? (
          <CourseOccasions course={name} occasions={filteredOccasions} />
        ) : (
          <CourseCardNoOccasions name={name} />
        )}
      </div>

      <span className="read-more">
        {filteredOccasions.length &&
        filteredOccasions.length < occasionsInTheFuture.length
          ? 'fler datum och läs mer'
          : 'läs mer'}
        <i className="read-more-icon fas fa-long-arrow-alt-right" />
      </span>

      {linkStyle.styles}

      <style jsx>
        {`
          .course-card {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 0;
            margin-top: 30px;
            margin-bottom: 20px;
            padding: 30px 30px 30px;
            background: ${theme === 'light' ? white : veryLightBluishGray};
            transition: 0.3s all ease;
          }

          .course-card:hover,
          .course-card:focus {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px 3px;
            transform: translateY(-5px);
          }

          .course-card:hover .read-more-icon {
            transform: translateX(4px);
          }

          .name {
            font-size: 32px;
            font-weight: bold;
            color: #000000;
          }
          .course.description {
            max-width: 90%;
          }

          .details {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 50px;
            margin-bottom: 15px;
          }
          .details-price,
          .details-length {
            margin: 0;
          }
          .details-price {
            flex: 1;
          }
          .details-length {
            flex: 2;
          }

          .occasions {
            display: flex;
            flex-wrap: wrap;
          }

          .read-more {
            display: inline-block;
            align-self: flex-end;
            margin-top: 20px;
            font-family: 'Lato';
            text-transform: uppercase;
            color: ${bluishPurple};
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;
          }

          .read-more-icon {
            margin-left: 15px;
            transition: 0.3s transform ease;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .wrapper {
              padding: 55px;
            }
            .details {
              flex-direction: row;
            }
          }

          /* Animation */
          .flip-enter {
            transition: all 200ms ease-in;
            transform: rotateX(90deg);
          }

          .flip-enter.flip-enter-active {
            transition: all 200ms ease-in;
            transform: rotateX(0deg);
          }

          .flip-leave {
            transition: all 200ms ease-in;
            opacity: 1;
          }

          .flip-leave.flip-leave-active {
            transform: rotateX(90deg);
            transition: all 200ms ease-in;
          }
        `}
      </style>
    </div>
  );
};

CourseCard.defaultProps = {
  theme: null,
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  showMaximum: PropTypes.number.isRequired,
  theme: PropTypes.string,
};

export default CourseCard;
