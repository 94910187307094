import React, { useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import CoursesSectionPresentational from './CoursesSection';

const CoursesSection = ({
  showCategories,
  showSearchField,
  padding,
  ...props
}) => {
  // fetch courses
  const data = useStaticQuery(graphql`
    query CoursesSectionQuery {
      courses: allDatoCmsCourse {
        edges {
          node {
            id
            name
            description
            length
            tagline
            price

            courseCategories {
              name
            }

            occasions: courseOccasions {
              id
              text
              location
              discount
              seats
              availableSeats
              allDates {
                datetime
              }
              courseLeaders {
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  `);

  const courses = data.courses.edges;

  // all states
  const [activeCourses, setActiveCourses] = useState(courses);
  const [activeCategory, setActiveCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // find and filter all categories (tags)
  const categories = courses
    .reduce(
      (allCourses, course) => [
        ...allCourses,
        ...course.node.courseCategories.map(({ name }) => name),
      ],
      []
    )
    .filter((category, i, array) => array.indexOf(category) === i);

  // filter
  const handleSetActiveCourses = useCallback(
    activeCategory => {
      if (!activeCategory) {
        setActiveCourses(courses);
      } else {
        const active = courses.filter(course => {
          const matches = course.node.courseCategories.filter(({ name }) => {
            if (!activeCategory) {
              return true;
            }
            return name === activeCategory;
          });

          if (matches.length) {
            return true;
          }

          return false;
        });
        setActiveCourses(active);
      }
    },
    [activeCategory, courses]
  );

  // when user click on a category button
  const handleChangeCategory = useCallback(
    categoryName => {
      if (categoryName === activeCategory) {
        setActiveCategory();
        handleSetActiveCourses();
      } else {
        setActiveCategory(categoryName);
        handleSetActiveCourses(categoryName);
      }
    },
    [activeCategory]
  );

  // when user type in search
  const handleSearch = useCallback(e => {
    const regex = new RegExp(e.target.value, 'i');
    const matches = courses.filter(course => regex.test(course.node.name));

    setIsLoading(false);
    setActiveCourses(matches);
  });

  // hide all courses
  const handleHideCourses = useCallback(() => {
    setIsLoading(true);
    setActiveCourses([]);
  });

  return (
    <CoursesSectionPresentational
      {...props}
      activeCategory={activeCategory}
      categories={categories}
      courses={activeCourses}
      handleChangeCategory={handleChangeCategory}
      handleHideCourses={handleHideCourses}
      handleSearch={handleSearch}
      isLoading={isLoading}
      padding={padding}
      showCategories={showCategories}
      showSearchField={showSearchField}
    />
  );
};

CoursesSection.defaultProps = {
  padding: false,
  showCategories: true,
  showSearchField: true,
};

CoursesSection.propTypes = {
  padding: PropTypes.bool,
  showCategories: PropTypes.bool,
  showSearchField: PropTypes.bool,
};

export default CoursesSection;
