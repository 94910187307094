import React, { useState, useEffect } from 'react';

import CookieBanner from './CookieBanner';

export default () => {
  const [cookieBannerIsClosed, setCookieBannerIsClosed] = useState(true);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);

  const didAcceptCookies = () => {
    const cookie = document.cookie;
    if (cookie.length < 1) {
      return false;
    } else {
      cookie
        .split(';')
        .filter(cookieString => cookieString.match(/cookie_notice_accepted/));
      let [, hasAccepted] = cookie.split('=');
      return !!hasAccepted;
    }
  };

  const onClose = () => {
    setCookieBannerIsClosed(true);
  };

  const acceptCookies = () => {
    setHasAcceptedCookies(true);
    document.cookie = 'cookie_notice_accepted=1';
  };

  useEffect(() => {
    const accepted = didAcceptCookies();
    if (accepted) {
      setHasAcceptedCookies(accepted);
    } else {
      setCookieBannerIsClosed(false);
    }
  }, []);

  if (hasAcceptedCookies || cookieBannerIsClosed) {
    return null;
  }

  return <CookieBanner onClose={onClose} acceptCookies={acceptCookies} />;
};
