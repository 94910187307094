import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Markdown from '../components/Markdown';
import css from 'styled-jsx/css';

import Section from '../components/Section';
import Document from '../components/Document';
import Banner from '../components/Banner';
import CoursesSection from '../components/pageSections/CoursesSection';

const sectionStyles = css.resolve`
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`;
const sectionWrapper = css.resolve`
  max-width: 700px;
`;

const CoursesPage = ({
  data: {
    datoCmsCoursesPage: {
      bannerImage,
      content,
      header,
      punchline,
      seo,
      coursesHeader,
    },
  },
}) => (
  <Document seo={seo}>
    <Banner bgImage={bannerImage} header={header} punchline={punchline} />
    {content && (
      <Section className={sectionStyles.className}>
        <div className={sectionWrapper.className}>
          <Markdown source={content} />
        </div>
      </Section>
    )}
    <CoursesSection header={coursesHeader} />

    {sectionStyles.styles}
    {sectionWrapper.styles}
  </Document>
);

export const query = graphql`
  {
    datoCmsCoursesPage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      header
      punchline
      content
      coursesHeader
      bannerImage {
        url
      }
    }
  }
`;

CoursesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CoursesPage;
