import React from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from '../../css-utilities';

const SectionFullWidth = ({
  children,
  noPadding,
  className,
  background,
  noMargin,
}) => (
  <section className={`section--full ${className ? className : ''}`}>
    {children}

    <style jsx>
      {`
        .section--full {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: 20px 1fr 1fr 20px;
          grid-column: -1 / 1;
          background: ${background ? background : 'none'};
          position: relative;
          padding: ${noPadding ? 0 : '50px 0'};
          margin: ${noMargin ? 0 : '50px 0'};
        }
        @media (min-width: ${breakpoints.tablet}) {
          .section--full {
            grid-template-columns: 13% 1fr 1fr 13%;
          }
        }
        @media (min-width: ${breakpoints.desktopMD}) {
          .section--full {
            grid-template-columns: 1fr repeat(2, minmax(350px, 550px)) 1fr;
          }
        }

        .section__content {
          grid-column: 1 / -1;
        }

        .skew--top-left::before,
        .skew--top-right::before {
          content: '';
          position: absolute;
          width: 100%;
          background: #fff;
          z-index: 1;
          height: 3.35vw;
        }

        .skew--top-left::before {
          top: -1.675vw;
          transform: skewy(-1.9deg);
        }

        .skew--top-right::before {
          top: -1.675vw;
          transform: skewy(1.9deg);
        }

        .skew--bottom-left::after,
        .skew--bottom-right::after {
          content: '';
          position: absolute;
          width: 100%;
          background: #fff;
          z-index: 1;
          height: 3.35vw;
        }

        .skew--bottom-left::after {
          bottom: -1.675vw;
          transform: skewy(1.9deg);
        }

        .skew--bottom-right::after {
          bottom: -1.675vw;
          transform: skewy(-1.9deg);
        }
      `}
    </style>
  </section>
);

SectionFullWidth.defaultProps = {
  background: null,
  className: null,
  noMargin: false,
  noPadding: false,
};

SectionFullWidth.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export { SectionFullWidth };
