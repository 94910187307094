import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from './icons/CloseIcon';
import HamburgerIcon from './icons/HamburgerIcon';

import { darkPurple, breakpoints } from '../../../css-utilities';

const MenuButton = ({ toggleNav, isNavOpen }) => {
  return (
    <button onClick={toggleNav}>
      {isNavOpen ? (
        <CloseIcon fill={darkPurple} />
      ) : (
        <HamburgerIcon fill={darkPurple} />
      )}
      <style jsx>
        {`
          button {
            display: block;
            padding: 30px;
            z-index: 15;
            background: none;
            border: none;
            cursor: pointer;
            position: ${isNavOpen ? 'fixed' : 'absolute'};
            top: 7px;
            right: 0;
          }

          @media (min-width: ${breakpoints.tablet}) {
            button {
              display: none;
            }
          }
        `}
      </style>
    </button>
  );
};

MenuButton.propTypes = {
  isNavOpen: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default MenuButton;
