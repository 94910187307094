import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import DocumentPresentational from './Document';

const Document = props => {
  const data = useStaticQuery(graphql`
    query DocumentQuery {
      datoCmsSite {
        favicon: faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  const favicon = data.datoCmsSite.favicon;

  return <DocumentPresentational favicon={favicon} {...props} />;
};

export default Document;
