import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import slugify from 'slugify';

import Link from '../../Link';

import {
  darkPurple,
  greenishcyan,
  veryLightBluishGray,
  veryLightpinkLighter,
  breakpoints,
} from '../../../css-utilities';

const Navbar = ({ isOpen, links }) => (
  <nav className={isOpen ? 'open' : ''}>
    {links &&
      links.length &&
      links.map(({ title, path }) => (
        <Link
          activeClassName={'active'}
          className={linkStyles.className}
          key={path}
          to={`/${slugify(path, { lower: true })}`}
        >
          {title}
        </Link>
      ))}

    {linkStyles.styles}

    <style jsx>
      {`
        nav {
          padding-top: 100px;
          position: fixed;
          top: 0;
          left: 0;
          display: none;
          height: 100vh;
          width: 100vw;
          overflow: auto;
        }

        .open {
          z-index: 10;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: ${veryLightBluishGray};
        }

        @media (min-width: ${breakpoints.tablet}) {
          nav {
            display: block;
            padding: 30px 0;

            position: static;
            height: auto;
            width: auto;
            background: none;
          }

          .open {
            display: block;
            background: none;
          }
        }

        @media (min-width: ${breakpoints.desktopSM}) {
          nav {
            padding: 30px 0;
          }
        }
      `}
    </style>
  </nav>
);

const linkStyles = css.resolve`
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    color: ${darkPurple};
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 32px 16px;
    outline: none;

    .active {
      background: ${veryLightpinkLighter};
    }

    :focus,
    :hover {
      background: ${veryLightpinkLighter};
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: auto;
      padding: 16px 16px;
      background: transparent;

      .active {
        background: transparent;
      }

      :focus,
      :hover {
        background: transparent;
      }

      :after {
        content: '';
        position: absolute;
        width: calc(100% - 32px);
        bottom: 10px;
        left: 16px;
        height: 3px;
        border-radius: 200px;
        background-color: ${greenishcyan};
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }

      .active:after {
        opacity: 0.4;
        transform: scaleX(1);
      }
      
      :focus:after,
      :hover:after {
        opacity: 1;
        transform: scaleX(1);
      }
    }

    :last-child {
      margin-right: 0px;
    }

    @media (min-width: ${breakpoints.desktopSM}) {
      margin-right: 15px;
    }
    @media (min-width: ${breakpoints.desktopMD}) {
      margin-right: 25px;

      
    }
`;

Navbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  links: PropTypes.array.isRequired,
};

export default Navbar;
