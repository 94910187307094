import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, dark, arrowDirection, onClick, className }) => (
  <button
    className={`btn ${dark ? 'dark' : ''} ${className}`}
    onClick={onClick}
  >
    {label}
    {arrowDirection && (
      <i className={`arrow fas fa-long-arrow-alt-${arrowDirection}`} />
    )}
  </button>
);

Button.defaultProps = {
  arrowDirection: null,
  className: '',
  dark: false,
  onClick: null,
};

Button.propTypes = {
  arrowDirection: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;
