import React from 'react';

import { darkishPink, breakpoints } from '../../../css-utilities';

const Ribbon = ({ text }) => (
  <div className="ribbon">
    {text}

    <style jsx>
      {`
        .ribbon {
          position: absolute;
          top: 0;
          width: 75px;
          background: ${darkishPink};
          color: white;
          text-align: center;
          font-family: 'Poppins';
          font-size: 12px;
          line-height: 1.42;
          right: 20px;
          height: 70px;
          padding: 20px 15px;
        }

        @media (min-width: ${breakpoints.tablet}) {
          .ribbon {
            right: 35px;
            height: auto;
            padding: 25px 15px;
          }
        }

        .ribbon::after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-right: 37.5px solid ${darkishPink};
          border-left: 37.5px solid ${darkishPink};
          border-top: 10px solid ${darkishPink};
          border-bottom: 10px solid transparent;
          bottom: -13%;
          left: 0;
        }
      `}
    </style>
  </div>
);

export default Ribbon;
