import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';

import Button from '../../../Button';

const CategoryButton = ({
  handleChangeCategory,
  activeCategory,
  categoryName,
}) => {
  const handleClick = useCallback(() => handleChangeCategory(categoryName), [
    activeCategory,
    categoryName,
  ]);

  return (
    <>
      <Button
        arrow="down"
        className={buttonWrapper.className}
        dark={categoryName === activeCategory ? true : false}
        key={categoryName}
        label={categoryName}
        onClick={handleClick}
      />

      {buttonWrapper.styles}
    </>
  );
};

CategoryButton.defaultProps = {
  activeCategory: '',
};

CategoryButton.propTypes = {
  activeCategory: PropTypes.string,
  categoryName: PropTypes.string.isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
};

const buttonWrapper = css.resolve`
  margin: 10px 15px 10px 0;
`;

export default CategoryButton;
