import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FooterPressentational from './Footer';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsFooter {
        links {
          title
          path
        }
        adress
        phone
        urlName
        urlPath
      }
    }
  `);

  return <FooterPressentational {...data.datoCmsFooter} />;
};

export default Footer;
