import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import Section from '../../Section';
import Column from '../../Column';
import Input from '../../Input';
import CourseCard from '../../CourseCard';
import CategoryButton from './CategoryButtons';

import { debounceEventWithImmediateCall } from '../../../lib/utils';

const CoursesSection = ({
  showCategories,
  showSearchField,
  courses,
  categories,
  handleChangeCategory,
  activeCategory,
  handleSearch,
  handleHideCourses,
  header,
  isLoading,
}) => (
  <>
    <Section>
      <Column width="100">
        <h2 className="heading">{header}</h2>
      </Column>

      {showCategories && (
        <Column width="60">
          {categories.map(categoryName => (
            <CategoryButton
              activeCategory={activeCategory}
              categoryName={categoryName}
              handleChangeCategory={handleChangeCategory}
              key={categoryName}
            />
          ))}
        </Column>
      )}

      {showSearchField && (
        <Column alignContent="right" width="40">
          <Input
            icon
            name="searchbox"
            noOffset
            onChange={debounceEventWithImmediateCall(
              handleSearch,
              1000,
              handleHideCourses
            )}
            placeholder="Sök utbildning..."
          />
        </Column>
      )}

      <Column width="100">
        <CSSTransitionGroup
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="flip"
        >
          {courses &&
            courses.length &&
            courses.map(course => (
              <CourseCard
                course={course}
                key={course.node.id}
                showMaximum={3}
              />
            ))}
        </CSSTransitionGroup>

        {courses.length === 0 && !isLoading && (
          <p className="no-result">Din sökning gav inga träffar</p>
        )}
      </Column>
    </Section>

    <style jsx>
      {`
        .heading {
          padding-bottom: 30px;
          font-family: Poppins;
          font-size: 32px;
          font-weight: bold;
          color: #000000;
        }
        .no-result {
          font-family: Poppins;
          font-size: 18px;
          font-weight: bold;
          color: #000000;
        }
      `}
    </style>
  </>
);

CoursesSection.defaultProps = {
  activeCategory: '',
};

CoursesSection.propTypes = {
  activeCategory: PropTypes.string,
  categories: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  handleHideCourses: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showCategories: PropTypes.bool.isRequired,
  showSearchField: PropTypes.bool.isRequired,
};

export default CoursesSection;
